<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input:focus {
  outline: none; /* 去除轮廓线 */
  box-shadow: none; /* 去除阴影 */
}
.btn{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
