const TokenKey = "Admin-Token";

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return localStorage.removeItem(TokenKey);
}

export function setStorage(name, storage) {
  return localStorage.setItem(name, storage);
}

export function getStorage(name) {
  return localStorage.getItem(name);
}

export function removeStorage(name) {
  return localStorage.removeItem(name);
}
