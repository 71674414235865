import Vue from "vue";
import App from "./App.vue";

// Element-UI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
// 路由
import router from "@/router/index";
// vuex
import store from "@/store/index";

// 拦截
import "@/router/permission";

// token
import { getToken, setToken, removeToken , setStorage , getStorage , removeStorage } from "@/utils/auth";
Vue.prototype.getToken = getToken;
Vue.prototype.setToken = setToken;
Vue.prototype.removeToken = removeToken;
Vue.prototype.setStorage = setStorage;
Vue.prototype.getStorage = getStorage;
Vue.prototype.removeStorage = removeStorage;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
